import { Fragment, useCallback, useMemo, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IInputChange, ITextAreaChange } from 'types';
import {
	DropItemsState,
	IDropedItems,
	IFieldNodeType,
	MenuTabState,
	useSetConfigDoc,
} from 'views/editor-dashboard';
import { PrepareDashboardState, templateTypeState } from 'views/prepare-dashboard';
import { useQueryParams } from 'hooks';
import { useUserKyc } from 'views/user-kyc';
import { formatDate } from 'utils';

import { InitialField } from '../components/initial-field';
import { SignField } from '../components/sign-field';
import { InputField } from '../components/input-field';
import { DateField } from '../components/date-field';

import { selfSignSelector } from './selector';

export const useSelfSign = (envId?: string) => {
	const [isLoaded, setIsLoaded] = useState(true);
	const { fetchTabMenuItems, fetchEnvelope } = useSetConfigDoc();
	const setDropItems = useSetRecoilState(DropItemsState);
	const { getEnvelopeIdParams } = useQueryParams({ updateState: false });
	const {fetchKycUrl} = useUserKyc();
	const menuTabs = useRecoilValue(MenuTabState);
	const templateType = useRecoilValue(templateTypeState);

	const envelopeId = useMemo(
		() => getEnvelopeIdParams(envId),
		[envId, getEnvelopeIdParams]
	);

	const fetchSelfSign = useCallback(async () => {
		setIsLoaded(false);
		if (!Object.keys(menuTabs).length ) {
			fetchTabMenuItems();
		}
		const envelope = await fetchEnvelope(envelopeId);
		const { notary, recipients } = envelope ?? {};
		if (notary) {
			const recipientId = recipients?.[0]?._id;
			const payload = {
				envelopeId,
				recipientId,
			};
			fetchKycUrl(payload);
		}
		setIsLoaded(true);
	}, [envelopeId, fetchEnvelope, fetchKycUrl, fetchTabMenuItems, menuTabs]);

	const handleChange = useCallback(
		(nodeId: string, e: IInputChange | string) => {
			const value = (e as IInputChange).target?.value ?? e;
			setDropItems((prev) => {
				const prevState = structuredClone(prev);
				const foundIndex = prevState.findIndex((dropItem: IDropedItems) => {
					return dropItem.id === nodeId;
				});
				if (foundIndex > -1) {
					const foundItem = prevState[foundIndex] as IDropedItems;
					if (foundItem.fieldType === 'text') {
						foundItem.metadata.editText = value;
					}
					if(foundItem.fieldType === 'radiogroup' || foundItem.questionType === 'radiogroup') { 
						prevState.forEach((tab: IDropedItems) => { 
							if (tab.name === foundItem.name) {
								tab.value = false;
							}
						})
					}
					const newItem = { ...foundItem, value: value };
					prevState.splice(foundIndex, 1, newItem);
				}
				return prevState;
			});
		},
		[setDropItems]
	);

	const getNodeValue = useCallback(
		(item: IDropedItems) => {
			if (item.fieldType === 'initial' || item.fieldType === 'signature') {
				return item.value?.path;
			}
			if (item.fieldType === 'date') {
				const value = item.value ?? formatDate(new Date(), 'yyyy/mm/dd');
				if (templateType === 'editable' && !item.value) {
					return '';
				}
				return value;
			}
			if (item.fieldType === 'text') {
				if (templateType === 'editable') {
					return item.metadata.editText ?? item.value ?? '';
				}
				return item.value ?? '';
			}
			return item.value ?? item.label;
		},
		[templateType]
	);

	const handleChangeTextArea = useCallback(
		(nodeId: string, event: ITextAreaChange) => {
			const { value } = event.target;
			handleChange(nodeId, value);
		},
		[handleChange]
	);

	const handleChangeCheckbox = useCallback(
		(nodeId: string, event: IInputChange) => {
			const value = event.target.checked;
			handleChange(nodeId, value?.toString());
		},
		[handleChange]
	);

	const getTheInputField = useCallback(
		(item: IDropedItems) => {
			const { metadata, id, label, key } = item;
			const { fontSize, height, fontColor, fontFamily } = metadata ?? {};
			// Converts fontSize to a number for comparison
			const fontSizeNumber = parseInt(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				`${(fontSize as any)?.value ?? fontSize ?? '8px'}`.replace('px', '')
			);
			const nodeHeightNumber = parseInt(`${height}`.replace('px', ''));
			// Determines whether to show a TextArea based on height and fontSize
			const showTextArea = nodeHeightNumber > fontSizeNumber * 3;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const newFontSize = (fontSize as any)?.value as string;
			const newLineHeight = `${fontSizeNumber + 2}px`;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const newFontFamily = (fontFamily as any)?.value as string;

			if (showTextArea) {
				return (
					<div className="self-sign-node self-sign-node__text">
						<textarea
							onChange={(e) => handleChangeTextArea(item.id, e)}
							value={getNodeValue(item)}
							rows={1}
							maxLength={Number(metadata.characterLimit ?? 100)}
							placeholder={`Enter ${label}`}
							style={{
								color: fontColor,
								fontSize: newFontSize,
								fontFamily: newFontFamily,
								lineHeight: newLineHeight,
							}}
						/>
					</div>
				);
			}
			return (
				<InputField
					className="self-sign-node self-sign-node__text"
					handleChange={handleChange}
					id={id}
					label={label}
					type="text"
					name={key}
					value={getNodeValue(item)}
					style={{ fontFamily, color: fontColor, fontSize }}
					maxLength={Number(metadata.characterLimit ?? 100)}
				/>
			);
		},
		[getNodeValue, handleChange, handleChangeTextArea]
	);

	const getSelfSignInputForFieldNode = useCallback(
		(
			nodeType: IFieldNodeType,
			item: IDropedItems,
			pageWidth: number,
			pageHeight: number,
			itemIndex: number
		) => {
			const { label, id, metadata, key } = item;
			const { fontFamily, fontColor, fontSize } = metadata;
			const isDraftTemp = templateType === 'editable';
			switch (nodeType) {
				case 'checkbox':
					return (
						<input
							className="self-sign-node self-sign-node__checkbox"
							type="checkbox"
							checked={isDraftTemp ? getNodeValue(item) === 'true' : true}
							onChange={(e) => handleChangeCheckbox(id, e)}
							readOnly={isDraftTemp ? false : true}
							placeholder={`Enter ${label}`}
						/>
					);
				case 'radiogroup':
					return (
						<input
							className="self-sign-node self-sign-node__checkbox"
							type="radio"
							name={item.name}
							checked={getNodeValue(item) === 'true'}
							onChange={(e) => {handleChangeCheckbox(id, e)}}
							placeholder={`Enter ${label}`}
						/>
					);
				case 'date':
					return (
						<DateField
							className="self-sign-node self-sign-node__date"
							handleChange={handleChange}
							id={id}
							name={key}
							format={metadata?.validation?.value}
							value={getNodeValue(item)}
							style={{ color: fontColor, fontFamily, fontSize }}
							pageWidth={pageWidth}
							pageHeight={pageHeight}
							node={item}
						/>
					);
				case 'email':
					return (
						<InputField
							className="self-sign-node self-sign-node__email"
							handleChange={handleChange}
							id={id}
							label={label}
							name={key}
							type="email"
							value={getNodeValue(item)}
							style={{ color: fontColor, fontFamily, fontSize }}
						/>
					);
				case 'initial':
					return (
						<InitialField
							value={getNodeValue(item)}
							item={item}
							pageHeight={pageHeight}
							pageWidth={pageWidth}
							index={itemIndex}
						/>
					);
				case 'hyperlink':
					return (
						<div className="self-sign-node self-sign-node__link">
							{label} <i className="ri-share-box-line"></i>
						</div>
					);
				case 'name':
					return (
						<InputField
							className="self-sign-node self-sign-node__name"
							handleChange={handleChange}
							id={id}
							label={label}
							type="text"
							name={key}
							value={getNodeValue(item)}
							style={{ color: fontColor, fontFamily, fontSize }}
						/>
					);
				case 'signature':
					return (
						<SignField
							value={getNodeValue(item)}
							item={item}
							pageHeight={pageHeight}
							pageWidth={pageWidth}
							index={itemIndex}
						/>
					);
				case 'text':
					return <Fragment>{getTheInputField(item)}</Fragment>;
				case 'title':
					return (
						<InputField
							className="self-sign-node self-sign-node__name"
							handleChange={handleChange}
							id={id}
							label={label}
							name={key}
							type="text"
							value={getNodeValue(item)}
							style={{ color: fontColor, fontFamily, fontSize }}
						/>
					);
				default:
					return <Fragment>{getTheInputField(item)}</Fragment>;
			}
		},
		[
			getNodeValue,
			getTheInputField,
			handleChange,
			handleChangeCheckbox,
			templateType,
		]
	);

	return {
		fetchSelfSign,
		selfSignLoaded: isLoaded,
		getSelfSignInputForFieldNode,
	};
};

type IDefaultValueType =
	| string
	| boolean
	| undefined
	| { path: string | undefined; id: string | undefined };

export const useGetSelfSign = () => {
	const { selfSigner } = useRecoilValue(selfSignSelector);
	const getTheDefaultValue = useMemo((): {
		[key: string]: IDefaultValueType;
	} => {
		const { fullName, email, signature, title } = selfSigner ?? {};
		const { initialDetail, signDetail } = signature ?? {};
		const { _id: initialId, document: initialDocument } = initialDetail ?? {};
		const { _id: signId, document: signDocument } = signDetail ?? {};
		const { path: initialPath } = initialDocument ?? {};
		const { path: signPath } = signDocument ?? {};
		return {
			name: fullName,
			email,
			title: title ?? '',
			signature: { path: signPath, id: signId },
			initial: { path: initialPath, id: initialId },
			checkbox: true,
			radiogroup: false,
		};
	}, [selfSigner]);

	return { getTheDefaultValue };
};

export const useSetSelfSign = () => {
	const setEnvelope = useSetRecoilState(PrepareDashboardState);
	const removeSignature = useCallback(
		(type: 'sign' | 'initial') => {
			setEnvelope((prev) => {
				const prevState = structuredClone(prev);
				const { recipients } = prevState ?? {};
				const { signature } = recipients?.[0] ?? {};
				if (signature) {
					if (type === 'sign') {
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						signature.signDetail = null as any;
					} else {
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						signature.initialDetail = null as any;
					}
				}
				return prevState;
			});
		},
		[setEnvelope]
	);

	return { removeSignature };
};
